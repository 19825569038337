import "@hotwired/turbo-rails"
import "@rails/request.js"
import axios from "axios"


window.addEventListener('DOMContentLoaded', function() {
  const _cloutdApi = axios.create({
    baseURL: import.meta.env.VITE_API_CLOUTD_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })

  const _createCloutdEvent = async function (eventName, payload) {
    const random = Math.random().toString(36).substring(2, 12)
    const now = Date.now()
    const eventID = `${random}.${now}`

    const data = {
      event_name: eventName,
      event_id: eventID,
      ...payload,
    }

    await window._cloutdApi.post('crm/meta_events', { data })

    if(window.fbq) {
      window.fbq("track", eventName, payload, { eventID })
    }
  }

  window._cloutdApi = _cloutdApi
  window._createCloutdEvent = _createCloutdEvent
})